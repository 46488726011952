@import-normalize;

$color-background: #0d0d0e;
$color-accent: #0286fa;
$color-text: #fff;
$color-fade: #343239;
$font-family: "Space Mono", monospace;

html {
  height: 100%;
}

body {
  font-family: $font-family;
  margin: 0;
  min-height: 100%;
  font-size: 13px;
  color: $color-text;
  background: $color-background;
  line-height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.app {
  position: relative;
  min-height: 560px;
  width: 360px;
  display: flex;
  flex-direction: column;
}

.app-header {
  width: 100%;
  z-index: 1;
  padding: 10px;
  position: absolute;
  nav {
    display: flex;
    justify-content: space-between;
  }
}

.app-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.app-content {
  min-height: 120px;
  width: 100%;
  margin: auto;
  position: relative;
}

.app-footer {
  padding: 10px 0;
}

.app-body {
  padding: 0 10px;
  transition: all 1s;
}

.app-body-enter {
  transform: translateX(100%);
  opacity: 0;
}
.app-body-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.app-body-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}
.app-body-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

a {
  color: $color-text;
  border-bottom: 1px solid $color-accent;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: $color-accent;
  }
}

button {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: $font-family;
  font-size: 13px;
  color: $color-text;
  background: $color-background;
  line-height: 1.5em;
  border-bottom: 1px solid $color-accent;
  transition: color 0.3s ease;
  outline: none;

  &:enabled {
    cursor: pointer;
  }

  &:hover {
    color: $color-accent;
  }
}

input {
  background: $color-background;
  border: 0;
  color: $color-accent;
  outline: none;
  font-family: $font-family;
  font-size: 30px;
  width: 100%;
  caret-color: $color-accent;
  text-transform: uppercase;
  border-bottom: 1px solid $color-fade;

  &[type="radio"] {
    display: none;
  }

  &::placeholder {
    color: $color-fade;
    padding-left: 1px;
  }
}

h1 {
  margin-bottom: 0.8em;
}

small {
  font-size: 9px;
  line-height: 1.6em;
  display: inline-block;
}

.asterisk {
  position: relative;
  top: -4px;
}
.highlight {
  color: $color-accent;
  text-transform: uppercase;
}

.ani-steps {
  position: relative;
}
.ani-steps-enter {
  opacity: 0;
  transform: translateX(110%);
}
.ani-steps-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 1s;
}
.ani-steps-exit {
  position: absolute;
  top: 0;
  opacity: 1;
}
.ani-steps-exit-active {
  opacity: 0;
  transform: translateX(-110%);
  transition: all 1s;
}

.location-nav {
  display: flex;
  justify-content: center;

  button {
    height: 42px;
    width: 42px;
    margin: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      border: 1px solid $color-accent;
      background-color: $color-background;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transition: all 0.4s ease;
    }

    &.active span {
      width: 42px;
      height: 42px;
    }

    &:hover:not([disabled]) span {
      background-color: $color-accent;
    }

    &:disabled span {
      border-color: $color-fade;
    }
  }
}

.input-wrapper {
  position: relative;
}

.btn-submit {
  position: absolute;
  right: 0;
  top: 6px;
  height: 42px;
  width: 42px;
  border: 1px solid $color-fade;
  transition: all 0.4s ease;

  svg {
    stroke: $color-fade;
    stroke-width: 1;
    left: 1px;
    top: 1px;
    position: relative;
  }

  &.active {
    border-color: $color-accent;
    svg {
      stroke: $color-accent;
    }
  }

  &:hover:not([disabled]) {
    border-color: $color-accent;
    background-color: $color-accent;
    svg {
      stroke: #fff;
    }
  }
}

.btn-large {
  font-size: 30px;
  margin-right: 0.8em;
}

.progress-bar {
  height: 6px;
  border: 1px solid $color-fade;

  span {
    display: block;
    height: 100%;
    background: $color-accent;
    transition: width 0.4s ease;
  }
}

.spinner {
  display: inline-block;
  width: 1px;
  height: 10px;
  background: $color-accent;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2,
h3 {
  font-size: 13px;
}

ul {
  list-style: square;
  padding-left: 26px;

  li {
    margin: 8px 0;

    &::marker {
      color: $color-accent;
    }
  }
}

p {
  margin-top: 0;
}

table {
  border-collapse: collapse;
  margin-bottom: 10px;
  margin-top: 30px;
  width: 100%;

  th {
    padding: 0;
    line-height: 1.2em;
  }
  tr {
    padding-top: 2px;
  }
  td {
    text-align: center;
    padding: 0;
    line-height: 1.8em;
  }
}
.table {
  &__title {
    th {
      text-align: left;
      border-bottom: 1px dashed;
    }
    td {
      border-bottom: 1px dashed;
    }
  }
  &__category {
    text-align: left;
    width: 245px;
  }
  &__total {
    td:first-of-type {
      padding: 2px 0 2px 2px;
    }
    border: 1px dashed;
  }

  &__date {
    font-size: 9px;
  }

  &__date-title {
    font-size: 9px;
    text-align: right;
  }
}

.error-text {
  color: red;
}

.pat-on-the-back {
  font-size: 10px;
  float: right;
}

.infomation-view {
  h1 {
    margin-top: 50px;
    line-height: 1em;
  }
  h3:first-child {
    margin-top: 50px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
